import { call, put, select, takeEvery } from 'redux-saga/effects';
import { superfetch } from '../../helper/fetchHelper';
import {
  FETCH_CONFIGURATION,
  FETCH_CONFIGURATION_SUCCESS,
} from './configurationReducer';

function* fetchConfiguration(action: any) {
  const { configurationKey, isHardRefetch } = action;
  const existingValue: any = yield select(
    (state) => state.configuration[configurationKey]
  );
  if (!existingValue || isHardRefetch) {
    try {
      const response: Response = yield call(
        superfetch,
        `/api/configurations/${configurationKey}`,
        {
          method: 'GET',
        }
      );
      if (response.ok) {
        const json: any = yield response.json();
        yield put({
          type: FETCH_CONFIGURATION_SUCCESS,
          configurationKey,
          value: json,
        });
      }
    } catch (e) {
      console.log(`Fetch ${configurationKey} failed.`);
    }
  }
}

export default function* configurationSaga() {
  yield takeEvery(FETCH_CONFIGURATION, fetchConfiguration);
}
